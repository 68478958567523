import React from 'react';
import { connect } from 'react-redux';
import PathPrefix from '../Utils/PathPrefixUtil';
import styled from 'styled-components';
import { withPage } from '../Page';
import ActionCreator from '../ActionCreator';
import Selectors from '../Selectors';
import * as Widget from '../Components/Widget';
import Link from '../Components/Link';
import Banner from '../Components/Banner';
import * as L from '../Utils/Lang';
import {DisplayNameOverlay} from '../Components/SquareItem';

class TabBar extends React.Component {
  render() {
    let { tabs, currentTab } = this.props;

    let catAllIdx = Math.floor(tabs.length / 2); // add a virtual cat `ALL`
    let allTabs = [
      ...tabs.slice(0, catAllIdx),
      { display_name_tw: 'ALL', display_name_en: 'ALL', id: 0 },
      ...tabs.slice(catAllIdx, tabs.length),
    ];

    return (
      <Tabs>
        {allTabs.map((tab, idx) => (
          <Tab key={idx}>
            <CategoryPage.StyledTabLink
              isActive={tab.id === currentTab}
              to={`/category/${tab.id}`}
            >
              {L.d(tab, 'display_name')}
            </CategoryPage.StyledTabLink>
          </Tab>
        ))}
      </Tabs>
    );
  }
}

class CategoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 3,
    };
  }

  handleChange = value => {
    this.setState({
      slideIndex: value,
    });
  };

  render() {
    let { category = {}, categories, items } = this.props;
    return (
      <Wrapper>
        <Banner
          image={PathPrefix('/images/pages/category/banner_projects.png')}
          text="PROJECTS"
        />

        <Widget.MaxWidth width={1000}>
          <TabBar tabs={categories} currentTab={category.id} />

          <CategoryPage.Content>
            {items
              .slice()
              .sort((a, b) => new Date(b.updated) - new Date(a.updated))
              .map((item, idx) => {
                if (idx === 0) {
                  return (
                    <CategoryPage.FirstItem image={item.image} key={idx}>
                      <Link
                        to={`/archive/${item.id}`}
                        extraStyle={{
                          width: '100%',
                          height: '100%',
                          textDecoration: 'none',
                        }}
                      >
                        <DisplayNameOverlay text={item.display_name_tw} />
                      </Link>
                    </CategoryPage.FirstItem>
                  );
                } else {
                  return (
                    <CategoryPage.SquareItemsFlex key={idx}>
                      <CategoryPage.SquareItemsWrapper image={item.image}>
                        <Link
                          to={`/archive/${item.id}`}
                          extraStyle={{
                            width: '100%',
                            height: '100%',
                            textDecoration: 'none',
                          }}
                        >
                          <DisplayNameOverlay text={item.display_name_tw} />
                        </Link>
                      </CategoryPage.SquareItemsWrapper>
                    </CategoryPage.SquareItemsFlex>
                  );
                }
              })}
          </CategoryPage.Content>
        </Widget.MaxWidth>
      </Wrapper>
    );
  }

  static Content = styled.div`
    margin: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 540px) {
      justify-content: center;
    }
  `;

  static FirstItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    height: 250px;
    background-color: lightgrey;
    background-image: url(${props => props.image});
    background-position: center;
    background-size: cover;
    width: 100%;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30vw;
      height: 125px;
      min-width: 125px;
      display: flex;
      color: #ffffff;
      font-size: 20px;
      background-color: RGB(0, 0, 0, 0.4);
    }
    @media screen and (max-width: 460px) {
      margin: 0 auto;
      vertical-align: middle;
      width: 250px;
      height: 250px;
    }
  `;
  static SquareItemsFlex = styled.div`
    display: flex;
    margin: 20px;
    flex-wrap: wrap;
    justify-content: space-between;

  `;

  static SquareItemsWrapper = styled.div`
    width: 250px;
    height: 250px;
    background-color: lightgrey;
    background-image: url(${props => props.image});
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  static StyledTabLink = styled(Link)`
    text-decoration: none;
    padding-bottom: 4px;
    color: grey;
    font-weight: ${props => (props.isActive ? 'bold' : 'normal')};
    border-bottom: ${props =>
      props.isActive ? '4px solid #4e4e4e' : '1px solid grey'};
    &:hover {
      font-weight: bold;
      border-bottom: 4px solid #4e4e4e;
    }
  `;
}

let Wrapper = styled.div``;
let Tabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 460px) {
    flex-wrap: wrap;
  }
`;
let Tab = styled.div`
  text-align: center;
  color: ${props => (props.isActive ? 'red' : 'grey')};
  margin: 40px 10px 0px 10px;
  min-width: 50px;
  height: 30px;
`;

export default withPage(
  connect(
    (state, ownProps) => {
      let paramId = Number(ownProps.location.pathname.split('/')[3]);
      return {
        category:
          paramId === 0
            ? {
                display_name_tw: 'ALL',
                display_name_en: 'ALL',
                id: 0,
                image: PathPrefix('/images/pages/category/cat_0.jpg'),
              }
            : Selectors.getCategoryById(state, paramId),
        items: Selectors.getItemsByCategoryId(state, paramId),
        categories: Selectors.getChigiCategories(state, paramId),
      };
    },
    ActionCreator
  )(CategoryPage)
);
