import React from 'react';
import styled from 'styled-components';

class DisplayNameOverlay extends React.Component {
    state = {
      mouseIn: false,
    };
    render() {
      let { text } = this.props;
      let { mouseIn } = this.state;
  
      return (
        <DisplayNameOverlay.Wrapper
          onMouseEnter={() => this.setState({ mouseIn: true })}
          onMouseLeave={() => this.setState({ mouseIn: false })}
        >
          <DisplayNameOverlay.Hover visibility={mouseIn}>
            {text}
          </DisplayNameOverlay.Hover>
        </DisplayNameOverlay.Wrapper>
      );
    }
    static Wrapper = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    `;
    static Hover = styled.div`
      /* text-decoration: none; */
      visibility: ${props => (props.visibility ? 'visible' : 'hidden')};
      cursor: pointer;
      color: white;
      background-color: rgb(0, 0, 0, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      @media screen and (max-width: 768px) {
        visibility: visible;
      }
    `;
}

let SquareItemsFlex = styled.div`
  display: flex;
  margin: 20px;
  flex-wrap: wrap;
  justify-content: space-between;

`;

let SquareItemsWrapper = styled.div`
  width: 250px;
  height: 250px;
  background-color: lightgrey;
  background-image: url(${props => props.image});
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
    DisplayNameOverlay,
    SquareItemsFlex,
    SquareItemsWrapper
}